import * as React from 'react';
import { FieldProps, useTranslate } from 'react-admin';

export const ListTitle = (props: FieldProps) => {
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.${props.resource}.name`, { smart_count: 2 })}
    </span>
  );
};
