import * as React from 'react';
import PropTypes from 'prop-types';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useInput, FieldTitle, InputProps } from 'ra-core';
import InputHelperText from '../FileInput/InputHelperText';
import { sanitizeInputRestProps } from 'react-admin';

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const defaultInputLabelProps = { shrink: true };

const getStringFromDate = (value: string | Date) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return null;
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};

const DateInput = ({
  defaultValue,
  format = getStringFromDate,
  initialValue,
  label,
  name,
  options,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant = 'filled',
  ...rest
}: DateInputProps) => {
  const { id, input, isRequired, meta } = useInput({
    defaultValue,
    format,
    formatOnBlur: true,
    initialValue,
    name,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const { error, submitError, touched } = meta;

  return (
    <TextField
      id={id}
      {...input}
      // Workaround https://github.com/final-form/react-final-form/issues/529
      // & https://github.com/final-form/react-final-form/issues/431
      value={format(input.value) || 'yes'}
      variant={variant}
      margin={margin}
      type="date"
      error={!!(touched && (error || submitError))}
      helperText={
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      }
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      InputLabelProps={defaultInputLabelProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  );
};

DateInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

DateInput.defaultProps = {
  options: {},
};

export type DateInputProps = InputProps<TextFieldProps> &
  Omit<TextFieldProps, 'helperText' | 'label'>;

export default DateInput;
