import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';
import {
  adminTaskStatusChoices,
  AdminTaskStatusesEnum,
} from './adminTask.definition';

const validationSchema = Yup.object().shape({
  photo: Yup.mixed().required(),
  status: Yup.string().required(),
  title: Yup.string(),
  publicationId: Yup.string().required(),
  companyId: Yup.string().required(),
  cityId: Yup.string().required(),
});

export const RecommendationRequestDefinition: Definition = {
  name: 'recommendationRequest',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'status',
      type: FieldDefinitionsEnum.SELECT,
      editable: true,
      choices: adminTaskStatusChoices,
      defaultValue: AdminTaskStatusesEnum.TODO,
    },
    {
      name: 'title',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'cityId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'city',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'companyId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'publicationId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'publication',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'photo',
      required: true,
      type: FieldDefinitionsEnum.IMAGE,
    },
    {
      name: 'recommendationId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'recommendation',
        displayField: 'id',
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
