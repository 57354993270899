import React from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { useApiCall } from 'api/useApiCall';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { createPublicationFromAdminTask } from 'api/requests/createPublicationFromAdminTask';
import { publicationValidationSchema } from 'definitions/publication.definition';

interface IProps {
  record: any;
  onClick: () => void;
}

export const CreatePublicationAction = ({ record, onClick }: IProps) => {
  const snackBarKeyRef = React.useRef<SnackbarKey>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const styles = useStyles();
  const handleClick = () => {
    try {
      publicationValidationSchema.validateSync(record);

      snackBarKeyRef.current = enqueueSnackbar('Создание объявления...', {
        persist: true,
        variant: 'info',
      });

      call(record);
    } catch (err) {
      if (err?.errors?.length) {
        enqueueSnackbar('Недостаточно полей чтобы создать объявление', {
          variant: 'error',
        });
      }
    }
    onClick();
  };

  const { call } = useApiCall<typeof createPublicationFromAdminTask>(
    createPublicationFromAdminTask,
    {
      onSuccess: (data) => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Объявление создано', {
          variant: 'success',
          action: () => (
            <Link className={styles.companyLink} to={`/publication/${data.id}`}>
              <Button color="default" size="small">
                Перейти
              </Button>
            </Link>
          ),
        });
      },
      onError: () => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Произошла ошибка при создании объявления', {
          variant: 'error',
        });
      },
    },
  );

  return <MenuItem onClick={handleClick}>Создать объявление</MenuItem>;
};

const useStyles = makeStyles({
  companyLink: {
    textDecorationColor: '#000',
  },
});
