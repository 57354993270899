import {
  ShowActionsProps,
  ListButton,
  EditButton,
  TopToolbar,
} from 'react-admin';

export const ShowActions = ({
  basePath,
  className,
  data,
  hasList,
  hasEdit,
}: ShowActionsProps) => (
  <TopToolbar className={className}>
    {hasList && <ListButton basePath={basePath} />}
    {hasEdit && <EditButton basePath={basePath} record={data} />}
  </TopToolbar>
);
