import { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { TableCellProps } from '@material-ui/core/TableCell';
import { DropzoneOptions } from 'react-dropzone';

type TextAlign = TableCellProps['align'];
type SortOrder = 'ASC' | 'DESC';

export interface FieldProps extends PublicFieldProps, InjectedFieldProps {}

export interface PublicFieldProps {
  addLabel?: boolean;
  sortBy?: string;
  sortByOrder?: SortOrder;
  source?: string;
  label?: string | ReactElement;
  sortable?: boolean;
  className?: string;
  cellClassName?: string;
  headerClassName?: string;
  formClassName?: string;
  textAlign?: TextAlign;
  emptyText?: string;
  fullWidth?: boolean;
}

// Props injected by react-admin
export interface InjectedFieldProps {
  basePath?: string;
  record?: FileState | FileState[];
  resource?: string;
}

export const fieldPropTypes = {
  addLabel: PropTypes.bool,
  sortBy: PropTypes.string,
  sortByOrder: PropTypes.oneOf<SortOrder>(['ASC', 'DESC']),
  source: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sortable: PropTypes.bool,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  textAlign: PropTypes.oneOf<TextAlign>([
    'inherit',
    'left',
    'center',
    'right',
    'justify',
  ]),
  emptyText: PropTypes.string,
};

export interface FileInputProps {
  accept?: string;
  children?: ReactNode;
  labelMultiple?: string;
  labelSingle?: string;
  maxSize?: number;
  minSize?: number;
  multiple?: boolean;
  placeholder?: ReactNode;
}

export interface FileInputOptions extends DropzoneOptions {
  inputProps?: any;
  onRemove?: (file: FileState) => void;
}

export enum FileSourceTypes {
  SERVER = 'server',
  INPUT = 'input',
}

export interface FileData {
  name: string;
  bucketKey: string;
  mimeType: string;
}

export type FileState =
  | {
      sourceType: FileSourceTypes.SERVER;
      data: FileData;
    }
  | {
      progress: number;
      file: File;
      sourceType: FileSourceTypes.INPUT;
      data: FileData;
    };
