import { TranslationMessages } from 'react-admin';
import russianMessages from 'ra-language-russian';

const socialMessages = {
  'socials.telegram': 'Telegram',
  'socials.viber': 'Viber',
  'socials.whatsapp': 'Whatsapp',
  'socials.instagram': 'Instagram',
  'socials.facebook': 'Facebook',
};

const customRussianMessages: TranslationMessages = {
  ...russianMessages,
  showTab: {
    summary: 'Общее',
    create: 'Создать %{name}',
  },
  menu: {
    categories: 'Категории',
    locations: 'Местоположение',
    requests: 'Заявки',
    content: 'Контент',
    admin: 'Администратор',
  },
  resources: {
    adPublication: {
      name: 'Рекламное объявление |||| Рекламные объявления',
      fields: {
        cityId: 'Город',
        publicationId: 'Объявление',
        companyId: 'Организация',
        comments: 'Комментарий',
        isActive: 'Активен',
        activatedAt: 'Активен с',
        activatedTill: 'Активен до',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    banner: {
      name: 'Баннер |||| Баннеры',
      fields: {
        cityId: 'Город',
        type: 'Тип',
        publicationId: 'Объявление',
        externalLink: 'Внешняя ссылка',
        companyId: 'Организация',
        mainPhoto: 'Главное фото',
        sidePhoto: 'Боковое фото',
        comments: 'Комментарий',
        isActive: 'Активен',
        activatedAt: 'Активен с',
        activatedTill: 'Активен до',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    bannerRequest: {
      name: 'Заявка на баннер |||| Заявки на баннер',
      fields: {
        cityId: 'Город',
        status: 'Статус',
        publicationId: 'Объявление',
        companyId: 'Организация',
        mainPhoto: 'Главное фото',
        sidePhoto: 'Боковое фото',
        bannerId: 'Баннер',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    callbackRequest: {
      name: 'Заявка на консультацию |||| Заявки на консультацию',
      fields: {
        companyName: 'Название организации',
        companyCategory: 'Сфере деятельности',
        phoneNumber: 'Телефонный номер',
        cityId: 'Город',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        status: 'Статус',
      },
    },
    company: {
      name: 'Организация |||| Организации',
      fields: {
        name: 'Название',
        contactName: 'Контактное имя',
        email: 'Почта',
        phoneNumbers: 'Телефонные номера',
        socials: 'Соц. сети',
        logo: 'Логотип',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        ...socialMessages,
      },
    },
    category: {
      name: 'Категория |||| Категории',
      fields: {
        name: 'Название',
        path: 'Путь',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    city: {
      name: 'Город |||| Города',
      fields: {
        name: 'Название',
        nativeName: 'Название на латинице',
        countryId: 'Страна',
        isActive: 'Активен',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    country: {
      name: 'Страна |||| Страны',
      fields: {
        name: 'Название',
        isActive: 'Активен',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    externalBannerRequest: {
      name: 'Заявка на внешний банер |||| Заявки на внешний банер',
      fields: {
        contactName: 'Контактное имя',
        email: 'Почта',
        status: 'Статус',
        phoneNumber: 'Телефонный номер',
        externalLink: 'Внешняя ссылка',
        cityName: 'Название города',
        mainPhoto: 'Главное фото',
        sidePhoto: 'Боковое фото',
        bannerId: 'Баннер',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    feedback: {
      name: 'Обратная связь |||| Обратная связь',
      fields: {
        name: 'Имя',
        phoneNumber: 'Телефонный номер',
        message: 'Сообщение',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    payment: {
      name: 'Оплата |||| Оплаты',
      fields: {
        total: 'Сумма',
        companyId: 'Организация',
        receipt: 'Чек',
        isPaid: 'Оплачено',
        details: 'Детали',
        type: 'Тип оплаты',
        days: 'Кол-во дней',
        perDayCost: 'Стоимость за день',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    promotion: {
      name: 'Акционное объявление |||| Акционные объявления',
      fields: {
        cityId: 'Город',
        photo: 'Фото',
        shortDescription: 'Краткое описание',
        fullDescription: 'Полное описание',
        discount: 'Размер скидки',
        publicationId: 'Объявление',
        categoryId: 'Категория',
        companyId: 'Организация',
        comments: 'Комментарий',
        isActive: 'Активен',
        activatedAt: 'Активен от',
        activatedTill: 'Активен до',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    promotionRequest: {
      name: 'Заявка на акционное объявление  |||| Заявки на акционные объявления',
      fields: {
        cityId: 'Город',
        status: 'Статус',
        photo: 'Фото',
        shortDescription: 'Краткое описание',
        fullDescription: 'Полное описание',
        discount: 'Размер скидки',
        publicationId: 'Объявление',
        subcategoryId: 'Подкатегория',
        companyId: 'Организация',
        promotionId: 'Акция',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    publication: {
      name: 'Объявление  |||| Объявления',
      fields: {
        subcategoryId: 'Подкатегория',
        status: 'Статус',
        phoneNumbers: 'Контактные номера',
        addressDetails: 'Адрес',
        email: 'Почта',
        website: 'Вебсайт',
        title: 'Название объявления',
        workingHours: 'Часы работы',
        description: 'Описание',
        photos: 'Фото',
        socials: 'Соц. сети',
        cityId: 'Город',
        companyId: 'Организация',
        comments: 'Комментарий',
        isActive: 'Активен',
        activatedAt: 'Активен от',
        activatedTill: 'Активен до',
        'addressDetails.city': 'Город',
        'addressDetails.street': 'Улица',
        'addressDetails.house': 'Дом',
        'addressDetails.building': 'Корпус',
        'addressDetails.apartment': 'Квартира',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        ...socialMessages,
      },
    },
    publicationRequest: {
      name: 'Заявка на объявление  |||| Заявки на объявления',
      fields: {
        contactName: 'Контактное имя',
        status: 'Статус',
        email: 'Почта',
        title: 'Название объявления',
        companyName: 'Название организации',
        companyLogo: 'Логотип',
        subcategoryId: 'Подкатегория',
        phoneNumbers: 'Контактные номера',
        addressDetails: 'Адрес',
        website: 'Вебсайт',
        workingHours: 'Часы работы',
        description: 'Описание',
        photos: 'Фото',
        socials: 'Соц. сети',
        cityName: 'Название города',
        'addressDetails.street': 'Улица',
        'addressDetails.house': 'Дом',
        'addressDetails.building': 'Корпус',
        'addressDetails.apartment': 'Квартира',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        cityId: 'Город',
        companyId: 'Организация',
        publicationId: 'Объявление',
        comments: 'Комментарии',
        ...socialMessages,
      },
    },
    recommendation: {
      name: 'Рекомендация  |||| Рекомендации',
      fields: {
        cityId: 'Город',
        photo: 'Фото',
        title: 'Заголовок',
        shortDescription: 'Краткое описание',
        publicationId: 'Объявление',
        categoryId: 'Категория',
        companyId: 'Организация',
        comments: 'Комментарий',
        isActive: 'Активен',
        activatedAt: 'Активен от',
        activatedTill: 'Активен до',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    recommendationRequest: {
      name: 'Заявка на рекомендацию  |||| Заявки на рекомендации',
      fields: {
        cityId: 'Город',
        status: 'Статус',
        photo: 'Фото',
        title: 'Заголовок',
        publicationId: 'Объявление',
        companyId: 'Организация',
        recommendationId: 'Рекомендация',
        comments: 'Комментарии',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    subcategory: {
      name: 'Подкатегория  |||| Подкатегории',
      fields: {
        name: 'Название',
        path: 'Путь',
        categoryId: 'Категория',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    adminTask: {
      name: 'Задача  |||| Задачи',
      fields: {
        status: 'Статус',
        subcategoryId: 'Подкатегория',
        phoneNumbers: 'Контактные номера',
        addressDetails: 'Адрес',
        email: 'Почта',
        website: 'Вебсайт',
        title: 'Название объявления',
        workingHours: 'Часы работы',
        description: 'Описание',
        photos: 'Фото',
        socials: 'Соц. сети',
        cityId: 'Город',
        publicationId: 'Объявление',
        companyId: 'Организация',
        companyName: 'Название организации',
        contactName: 'Контактное имя',
        comments: 'Комментарий',
        'addressDetails.city': 'Город',
        'addressDetails.street': 'Улица',
        'addressDetails.house': 'Дом',
        'addressDetails.building': 'Корпус',
        'addressDetails.apartment': 'Квартира',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        ...socialMessages,
      },
    },
    adminTaskHistory: {
      name: 'Архивированная задача  |||| Архивированные задачи',
      fields: {
        status: 'Статус',
        subcategoryId: 'Подкатегория',
        phoneNumbers: 'Контактные номера',
        addressDetails: 'Адрес',
        email: 'Почта',
        website: 'Вебсайт',
        title: 'Название объявления',
        workingHours: 'Часы работы',
        publicationId: 'Объявление',
        description: 'Описание',
        photos: 'Фото',
        socials: 'Соц. сети',
        cityId: 'Город',
        companyId: 'Организация',
        companyName: 'Название организации',
        contactName: 'Контактное имя',
        comments: 'Комментарий',
        'addressDetails.city': 'Город',
        'addressDetails.street': 'Улица',
        'addressDetails.house': 'Дом',
        'addressDetails.building': 'Корпус',
        'addressDetails.apartment': 'Квартира',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        ...socialMessages,
      },
    },
  },
  ra: {
    ...russianMessages.ra,
    action: {
      ...russianMessages.ra.action,
      clear_filter: 'Очистить фильтр',
    },
  },
};

export default customRussianMessages;
