import * as React from 'react';
import { cloneElement, useMemo, useContext, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  sanitizeListRestProps,
  Identifier,
  SortPayload,
  Exporter,
  useListContext,
  useResourceContext,
  useResourceDefinition,
} from 'ra-core';
import { ToolbarProps } from '@material-ui/core';
import {
  CreateButton,
  ExportButton,
  FilterButton,
  FilterContext,
  TopToolbar,
  Button,
} from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  toolbar: {
    minWidth: 180,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
  },
}));

const ListActions = (
  props: ListActionsProps & { setFilters?: (value: any) => void },
) => {
  const { className, exporter, filters: filtersProp, ...rest } = props;
  const classes = useStyles();
  const {
    currentSort,
    displayedFilters,
    filterValues,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext(props);
  const resource = useResourceContext(rest);
  const { hasCreate } = useResourceDefinition(rest);
  const filters = useContext(FilterContext) || filtersProp;
  return useMemo(
    () => (
      <TopToolbar className={classes.toolbar} {...sanitizeListRestProps(rest)}>
        {hasCreate && <CreateButton basePath={basePath} />}
        {filtersProp
          ? cloneElement(filtersProp, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })
          : filters && (
              <>
                {!!Object.values(filterValues).length && (
                  <Button
                    className={classes.deleteButton}
                    label="ra.action.clear_filter"
                    onClick={() => props.setFilters({})}
                  >
                    <ClearIcon />
                  </Button>
                )}
                <FilterButton />
              </>
            )}
        {exporter !== false && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
          />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total],
  );
};

ListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.any,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export interface ListActionsProps extends ToolbarProps {
  currentSort?: SortPayload;
  className?: string;
  resource?: string;
  filters?: ReactElement<any>;
  displayedFilters?: any;
  exporter?: Exporter | boolean;
  filterValues?: any;
  permanentFilter?: any;
  hasCreate?: boolean;
  basePath?: string;
  selectedIds?: Identifier[];
  onUnselectItems?: () => void;
  showFilter?: (filterName: string, defaultValue: any) => void;
  total?: number;
}

export default ListActions;
