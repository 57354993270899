import * as React from 'react';
import { FieldProps, useTranslate } from 'react-admin';

export const ShowTitle = (props: FieldProps & { titleField: string }) => {
  const { record, titleField } = props;
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.${props.resource}.name`, { smart_count: 1 })} &quot;
      {record[titleField]}&quot;
    </span>
  );
};
