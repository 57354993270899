import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { CreateActions } from '../components/actions/CreateActions';
import * as React from 'react';
import { ReactElement } from 'react';
import {
  FieldChoice,
  FieldCustomComponents,
  FieldDefinition,
  FieldDefinitionLayout,
  FieldDefinitionsEnum,
  FieldDefinitionStaticLayout,
  FieldReferenceDefinition,
  ViewTypesEnum,
} from '../types/definition';
import { GeneratedViewOptions } from './types';
import { JsonInput } from '../components/JsonInput';
import ImageInput from '../components/ImageInput';
import FileInput from '../components/FileInput';
import ImageField from '../components/ImageInput/ImageField';
import FileField from '../components/FileInput/FileField';
import { validateSchema } from '../helpers/yup';
import { checkAuthentication } from '../helpers/hooks/checkAuthentication';
import View from '../layout/View';
import { CreateTitle } from '../components/titles/CreateTitle';
import OptionalVisibilityInput from '../components/OptionalVisiblityInput';
import DateInput from '../components/DateInput';

const mapFieldDefinitionToCreateField = (
  resource: string,
  fieldDefinition: FieldDefinition,
): ReactElement => {
  if (fieldDefinition.visibilityCondition) {
    return (
      <OptionalVisibilityInput
        resource={resource}
        definition={fieldDefinition}
        mapper={mapFieldDefinitionToCreateField}
      />
    );
  }

  switch (fieldDefinition.type) {
    case FieldDefinitionsEnum.URL:
    case FieldDefinitionsEnum.STRING: {
      return (
        <TextInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        />
      );
    }
    case FieldDefinitionsEnum.TEXT: {
      return (
        <TextInput
          multiline
          minRows={7}
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        />
      );
    }
    case FieldDefinitionsEnum.SELECT: {
      const choices = fieldDefinition.choices as FieldChoice[];
      return (
        <SelectInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          choices={choices}
        />
      );
    }
    case FieldDefinitionsEnum.DATE: {
      return (
        <DateInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        />
      );
    }
    case FieldDefinitionsEnum.DATETIME: {
      return (
        <DateTimeInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        />
      );
    }
    case FieldDefinitionsEnum.MULTIPLE_IMAGES:
    case FieldDefinitionsEnum.IMAGE: {
      return (
        <ImageInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          multiple={
            fieldDefinition.type === FieldDefinitionsEnum.MULTIPLE_IMAGES
          }
        >
          <ImageField />
        </ImageInput>
      );
    }
    case FieldDefinitionsEnum.MULTIPLE_FILES:
    case FieldDefinitionsEnum.FILE: {
      return (
        <FileInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          multiple={
            fieldDefinition.type === FieldDefinitionsEnum.MULTIPLE_FILES
          }
        >
          <FileField />
        </FileInput>
      );
    }
    case FieldDefinitionsEnum.BOOLEAN: {
      return (
        <BooleanInput
          resource={resource}
          isRequired={fieldDefinition.required}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        />
      );
    }
    case FieldDefinitionsEnum.JSON_ARRAY:
    case FieldDefinitionsEnum.SIMPLE_ARRAY: {
      const layout = fieldDefinition.layout as FieldDefinitionStaticLayout;
      return (
        <ArrayInput
          resource={resource}
          validate={fieldDefinition.required && [required()]}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
        >
          <SimpleFormIterator>
            {layout.map((l: any) =>
              mapFieldDefinitionToCreateField(resource, l),
            )}
          </SimpleFormIterator>
        </ArrayInput>
      );
    }
    case FieldDefinitionsEnum.JSON: {
      const layout = fieldDefinition.layout as FieldDefinitionLayout;
      return (
        <JsonInput
          source={fieldDefinition.name}
          layout={layout}
          mapper={mapFieldDefinitionToCreateField}
        />
      );
    }
    case FieldDefinitionsEnum.REFERENCE: {
      const reference = fieldDefinition.reference as FieldReferenceDefinition;
      return (
        <ReferenceInput
          resource={resource}
          isRequired={fieldDefinition.required}
          reference={reference.target}
          source={fieldDefinition.name}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
          filterToQuery={(searchText) =>
            !!searchText && { [reference.displayField]: searchText || '' }
          }
        >
          <AutocompleteInput resettable optionText={reference.displayField} />
        </ReferenceInput>
      );
    }
    case FieldDefinitionsEnum.CUSTOM: {
      const customComponents =
        fieldDefinition.customComponents as FieldCustomComponents;
      const Input = customComponents.input;
      return (
        <Input
          resource={resource}
          isRequired={fieldDefinition.required}
          disabled={fieldDefinition.disabled?.[ViewTypesEnum.CREATE]}
          source={fieldDefinition.name}
        />
      );
    }
  }
  return null;
};

export const CreateView = (props: CreateProps) => {
  checkAuthentication();
  const { fields, validationSchema } = props.options as GeneratedViewOptions;
  const mappedFields = React.useMemo(
    () =>
      fields
        .filter((field) => !field.hidden?.[ViewTypesEnum.CREATE])
        .map((field) => mapFieldDefinitionToCreateField(props.resource, field))
        .filter((field) => !!field),
    [fields],
  );

  return (
    <View title={<CreateTitle resource={props.resource} />}>
      <Create title=" " actions={<CreateActions />} {...props}>
        <SimpleForm
          validate={validationSchema && validateSchema(validationSchema)}
        >
          {React.Children.toArray(mappedFields)}
        </SimpleForm>
      </Create>
    </View>
  );
};
