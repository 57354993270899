import Yup from 'yup-custom';
import {
  Definition,
  FieldChoice,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';

const validationSchema = Yup.object().shape({
  total: Yup.number().required(),
  companyId: Yup.string().required(),
  details: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required(),
        days: Yup.string().required(),
        perDayCost: Yup.string().required(),
      }),
    )
    .required(),
});

const paymentDetailChoices: FieldChoice[] = [
  {
    id: 'publication',
    name: 'Публикация',
  },
  {
    id: 'ad_publication',
    name: 'Рекламное объявление',
  },
  {
    id: 'recommendation',
    name: 'Рекомендация',
  },
  {
    id: 'banner',
    name: 'Баннер',
  },
];

export const PaymentDefinition: Definition = {
  name: 'payment',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'total',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'receipt',
      type: FieldDefinitionsEnum.FILE,
    },
    {
      name: 'isPaid',
      type: FieldDefinitionsEnum.BOOLEAN,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'companyId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'details',
      required: true,
      type: FieldDefinitionsEnum.JSON_ARRAY,
      layout: [
        {
          name: 'type',
          required: true,
          type: FieldDefinitionsEnum.SELECT,
          choices: paymentDetailChoices,
        },
        {
          name: 'days',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'perDayCost',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
