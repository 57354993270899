import { Definition } from '../types/definition';
import { ResourceProps } from 'react-admin';
import { ListView } from './ListView';
import { CreateView } from './CreateView';
import { EditView } from './EditView';
import ShowView from './ShowView';

export const mapResourceProps = (
  definition: Definition,
  definitions: Definition[],
): ResourceProps => {
  return {
    name: definition.name,
    list: ListView,
    edit: EditView,
    create: CreateView,
    show: ShowView,
    options: { ...definition, definitions },
  };
};
