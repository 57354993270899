import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import { sanitizeFieldRestProps } from 'react-admin';
import {
  fieldPropTypes,
  FileData,
  FileSourceTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from '../../types/upload';
import { useEffect } from 'react';

const getUrlFromFileData = (data: FileData) =>
  `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${data.bucketKey}`;

const FileField = (props: FileFieldProps) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    title,
    src,
    target,
    download,
    ping,
    rel,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const classes = useStyles(props);

  const imageUrls = React.useRef(
    Array.isArray(record)
      ? record.map((r) =>
          r.sourceType === FileSourceTypes.INPUT
            ? URL.createObjectURL(r.file)
            : getUrlFromFileData(r.data),
        )
      : record.sourceType === FileSourceTypes.INPUT
      ? URL.createObjectURL(record.file)
      : getUrlFromFileData(record.data),
  );

  useEffect(() => {
    return () => {
      Array.isArray(record)
        ? record.forEach((r, i) => {
            if (r.sourceType === FileSourceTypes.INPUT) {
              URL.revokeObjectURL(imageUrls.current[i]);
            }
          })
        : URL.revokeObjectURL(imageUrls.current as string);
    };
  }, [record]);

  if (!record) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div
        className={classnames(classes.root, className)}
        {...sanitizeFieldRestProps(rest)}
      />
    );
  }

  if (Array.isArray(record)) {
    return (
      <ul
        className={classnames(classes.root, className)}
        {...sanitizeFieldRestProps(rest)}
      >
        {record.map((file, index) => {
          const fileTitleValue = (title && get(file, title)) || title;
          return (
            <li key={index}>
              <a
                href={imageUrls.current[index]}
                title={fileTitleValue}
                target={target}
                download={download}
                ping={ping}
                rel={rel}
              >
                {fileTitleValue}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  const titleValue = (title && get(record, title)) || title;

  return (
    <div
      className={classnames(classes.root, className)}
      {...sanitizeFieldRestProps(rest)}
    >
      <a
        href={imageUrls.current as string}
        title={titleValue}
        target={target}
        download={download}
        ping={ping}
        rel={rel}
      >
        {titleValue}
      </a>
    </div>
  );
};

FileField.defaultProps = {
  addLabel: true,
};

const useStyles = makeStyles(
  {
    root: { display: 'inline-block' },
  },
  { name: 'RaFileField' },
);

export interface FileFieldProps extends PublicFieldProps, InjectedFieldProps {
  source?: string;
  src?: string;
  title?: string;
  target?: string;
  download?: boolean | string;
  ping?: string;
  rel?: string;
  classes?: Record<string, any>;
}

FileField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ping: PropTypes.string,
  rel: PropTypes.string,
};

export default FileField;
