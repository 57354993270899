import { setIn } from 'final-form';

export const validateSchema = (schema) => async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    console.log(err.inner, 'errors');
    return err.inner.reduce(
      (formError, innerError) =>
        setIn(formError, innerError.path, innerError.message),
      {},
    );
  }
};
