import { FileData } from '../../types/upload';
import { v4 as uuid } from 'uuid';
import { s3Client } from '../../constants/aws';

export const copyFile = async (file: FileData): Promise<FileData> => {
  const key = `uploaded-files/${uuid()}/${file.name}`;
  const bucket = process.env.REACT_APP_AWS_S3_BUCKET as string;
  await s3Client
    .copyObject({
      Bucket: bucket,
      Key: key,
      ACL: 'public-read',
      CopySource: `/${bucket}/${file.bucketKey}`,
    })
    .promise();
  return {
    name: file.name,
    bucketKey: key,
    mimeType: file.mimeType,
  };
};
