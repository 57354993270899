import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
} from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import { useDropzone, FileRejection } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import { useInput, useTranslate, InputProps } from 'ra-core';

import { Labeled, sanitizeInputRestProps } from 'react-admin';
import FileInputPreview from './FileInputPreview';
import InputHelperText from './InputHelperText';
import { v4 as uuid } from 'uuid';
import { s3Client } from '../../constants/aws';
import {
  FileData,
  FileInputOptions,
  FileInputProps,
  FileSourceTypes,
  FileState,
} from '../../types/upload';

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' },
);

const FileInput = (props: FileInputProps & InputProps<FileInputOptions>) => {
  const {
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: {
      inputProps: inputPropsOptions,
      ...options
    } = {} as FileInputOptions,
    parse,
    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format,
    parse: parse,
    source,
    type: 'file',
    validate,
    ...rest,
  });
  const { touched, error, submitError } = meta;
  const files: FileData[] = value
    ? Array.isArray(value)
      ? value
      : [value]
    : [];
  const [uploadedFiles, setUploadedFiles] = React.useState<FileState[]>([
    ...files.map<FileState>((data) => ({
      sourceType: FileSourceTypes.SERVER,
      data,
    })),
  ]);

  const onDrop = async (
    newFiles: File[],
    rejectedFiles: FileRejection[],
    event: any,
  ) => {
    if (multiple) {
      const data: FileData[] = [];
      for (const newFile of newFiles) {
        const bucketKey = `uploaded-files/${uuid()}/${newFiles[0].name}`;
        setUploadedFiles((state) => [
          ...state,
          {
            sourceType: FileSourceTypes.INPUT,
            file: newFile,
            progress: 0,
            data: {
              bucketKey: bucketKey,
              name: newFile.name,
              mimeType: newFile.type,
            },
          },
        ]);
        try {
          await s3Client
            .upload({
              Bucket: process.env.REACT_APP_AWS_S3_BUCKET as string,
              Key: bucketKey,
              ACL: 'public-read',
              Body: newFile,
            })
            .on('httpUploadProgress', ({ loaded, total }) => {
              const progress = Math.round((100 * loaded) / total);
              setUploadedFiles((state) => {
                const uploadedFileState = state.find(
                  (fileState) => fileState.data.bucketKey === bucketKey,
                ) as FileState;
                const filteredState = state.filter(
                  (fileState) => fileState.data.bucketKey !== bucketKey,
                );
                return [
                  ...filteredState,
                  {
                    ...uploadedFileState,
                    progress,
                  },
                ];
              });
            })
            .promise();

          setUploadedFiles((state) => {
            const uploadedFileState = state.find(
              (fileState) => fileState.data.bucketKey === bucketKey,
            ) as FileState;
            const filteredState = state.filter(
              (fileState) => fileState.data.bucketKey !== bucketKey,
            );
            return [
              ...filteredState,
              {
                ...uploadedFileState,
                progress: 100,
              },
            ];
          });
          data.push({
            bucketKey: bucketKey,
            name: newFile.name,
            mimeType: newFile.type,
          });
        } catch (e) {
          console.error(e, 'error during file upload');
          setUploadedFiles((state) => {
            const filteredState = state.filter(
              (fileState) => fileState.data.bucketKey !== bucketKey,
            );
            return [...filteredState];
          });
        }
      }
      onChange([...files, ...data]);
    } else {
      const bucketKey = `uploaded-files/${uuid()}/${newFiles[0].name}`;

      setUploadedFiles([
        {
          sourceType: FileSourceTypes.INPUT,
          file: newFiles[0],
          progress: 0,
          data: {
            bucketKey,
            name: newFiles[0].name,
            mimeType: newFiles[0].type,
          },
        },
      ]);

      try {
        await s3Client
          .upload({
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET as string,
            Key: bucketKey,
            ACL: 'public-read',
            Body: newFiles[0],
          })
          .on('httpUploadProgress', ({ loaded, total }) => {
            const progress = Math.round((100 * loaded) / total);
            setUploadedFiles([
              {
                sourceType: FileSourceTypes.INPUT,
                file: newFiles[0],
                progress,
                data: {
                  bucketKey,
                  name: newFiles[0].name,
                  mimeType: newFiles[0].type,
                },
              },
            ]);
          })
          .promise();
        setUploadedFiles([
          {
            sourceType: FileSourceTypes.INPUT,
            file: newFiles[0],
            progress: 100,
            data: {
              bucketKey,
              name: newFiles[0].name,
              mimeType: newFiles[0].type,
            },
          },
        ]);
        onChange({
          bucketKey,
          name: newFiles[0].name,
          mimeType: newFiles[0].type,
        });
      } catch (e) {
        console.error(e, 'error during file upload');
        onChange(null);
      }
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event);
    }
  };

  const onRemove = (file: FileState) => async () => {
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file),
      );
      const filteredUploadedFiles = uploadedFiles.filter(
        (stateFile) => !shallowEqual(stateFile, file),
      );
      setUploadedFiles(filteredUploadedFiles);
      onChange(filteredFiles as any);
    } else {
      setUploadedFiles([]);
      onChange(null);
    }

    if (options.onRemove) {
      options.onRemove(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? (Children.only(children) as ReactElement<any>)
      : undefined;

  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          data-testid="dropzone"
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        <FormHelperText>
          <InputHelperText
            touched={touched || false}
            error={error || submitError}
            helperText={helperText}
          />
        </FormHelperText>
        <div className="previews">
          {uploadedFiles.map((file) => (
            <FileInputPreview
              key={file.data.bucketKey}
              sourceType={file.sourceType}
              file={
                file.sourceType === FileSourceTypes.INPUT
                  ? file?.file
                  : undefined
              }
              data={file.data}
              progress={
                file.sourceType === FileSourceTypes.INPUT
                  ? file?.progress
                  : undefined
              }
              onRemove={onRemove(file)}
              className={classes.removeButton}
            >
              {cloneElement(childrenElement as ReactElement, {
                record: file,
                className: classes.preview,
              })}
            </FileInputPreview>
          ))}
        </div>
      </>
    </Labeled>
  );
};

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
};

export default FileInput;
