import { getClient } from '../client';

interface RestoreAdminTaskFromHistoryResponse {
  id: string;
}

export const restoreAdminTaskFromHistory = async (record: any) => {
  const client = getClient();
  const response = await client.post<RestoreAdminTaskFromHistoryResponse>(
    'adminTask',
    record,
  );
  // TODO: in the future you will need to copy all images if you add subscriber for deletion
  await client.delete(`adminTaskHistory/${record.id}`);
  return response.data;
};
