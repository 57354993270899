import * as React from 'react';
import { FieldProps, useTranslate } from 'react-admin';

export const CreateTitle = (props: FieldProps) => {
  const t = useTranslate();
  return (
    <span>
      {t('showTab.create', {
        name: t(`resources.${props.resource}.name`, {
          smart_count: 1,
        }).toLowerCase(),
      })}
    </span>
  );
};
