import { FieldDefinition } from 'types/definition';
import React from 'react';
import { useFormState } from 'react-final-form';
import { useInput } from 'ra-core';

interface IProps {
  resource: string;
  definition: FieldDefinition;
  mapper: (resource: string, fieldDefinition: FieldDefinition) => any;
}

const OptionalVisibilityInput = (props: IProps) => {
  const { visibilityCondition, ...definition } = props.definition;
  const component = props.mapper(props.resource, definition);
  const { values } = useFormState();
  const {
    input: { onChange, value },
  } = useInput({ source: props.definition.name, ...props });

  if (!visibilityCondition(values)) {
    if (value) {
      onChange(undefined);
    }
    return null;
  }

  return (
    <>
      {React.cloneElement(component, {
        ...props,
      })}
    </>
  );
};

export default OptionalVisibilityInput;
