import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';

const validationSchema = Yup.object().shape({
  cityId: Yup.string().required(),
  publicationId: Yup.string().required(),
  companyId: Yup.string().required(),
});

export const AdPublicationDefinition: Definition = {
  name: 'adPublication',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'cityId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'city',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'publicationId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'publication',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'companyId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'isActive',
      type: FieldDefinitionsEnum.BOOLEAN,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'activatedAt',
      type: FieldDefinitionsEnum.DATE,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'activatedTill',
      type: FieldDefinitionsEnum.DATE,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
