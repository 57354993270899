import React from 'react';
import { ACCESS_TOKEN_STORE_KEY } from '../../constants/auth';
import { useHistory } from 'react-router-dom';

export const checkAuthentication = () => {
  const history = useHistory();
  React.useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
    if (!token) {
      history.push('/login');
    }
  }, []);
};
