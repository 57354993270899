import axios from 'axios';
import { ACCESS_TOKEN_STORE_KEY } from 'constants/auth';

export const getClient = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  });
};
