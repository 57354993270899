import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';

export const validationSchema = Yup.object().shape({
  contactName: Yup.string().nullable(),
  email: Yup.string().required(),
  phoneNumbers: Yup.array().of(Yup.string().required()).min(1).required(),
});

export const CompanyDefinition: Definition = {
  name: 'company',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'name',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'contactName',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'email',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'phoneNumbers',
      required: true,
      type: FieldDefinitionsEnum.SIMPLE_ARRAY,
      layout: [
        {
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'socials',
      type: FieldDefinitionsEnum.JSON,
      layout: [
        {
          name: 'viber',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'telegram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'instagram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'whatsapp',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'facebook',
          type: FieldDefinitionsEnum.STRING,
        },
      ],
    },
    {
      name: 'logo',
      type: FieldDefinitionsEnum.IMAGE,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
