import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  ReduxState,
} from 'react-admin';
import { AppState } from '../types';

import SubMenu from './SubMenu';
import { useState } from 'react';
import { menuLayout } from '../menuLayout';
import { Link, useLocation } from 'react-router-dom';
import { setSidebarVisibility } from 'ra-core';
import Logo from './logo.png';

const Menu = () => {
  const { pathname } = useLocation();
  const path = pathname.substring(1);
  const openedMenu = menuLayout.find((menu) =>
    menu.submenu?.find((submenu) => submenu.name === path),
  );

  const [state, setState] = useState<Record<string, boolean>>({
    ...(openedMenu && { [openedMenu.name]: true }),
  });
  const translate = useTranslate();
  const dispatch = useDispatch();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const handleToggle = (menu: string) => {
    setState((state) => ({ [menu]: !state[menu] }));
    if (!sidebarIsOpen) {
      toggleSidebar();
    }
  };

  const sidebarIsOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen,
  );

  const mappedMenuLayout = React.useMemo(
    () =>
      menuLayout.map((menu) => {
        if (menu.submenu) {
          return (
            <SubMenu
              key={menu.name}
              handleToggle={() => handleToggle(menu.name)}
              isOpen={sidebarIsOpen && state[menu.name]}
              name={translate(`menu.${menu.name}`)}
              icon={menu.icon && <menu.icon />}
            >
              {menu.submenu.map((submenu) => (
                <MenuItemLink
                  key={submenu.name}
                  to={{
                    pathname: `/${submenu.name}`,
                    state: { _scrollToTop: true },
                  }}
                  primaryText={translate(`resources.${submenu.name}.name`, {
                    smart_count: 2,
                  })}
                  className={classes.submenu}
                  leftIcon={submenu.icon && <submenu.icon />}
                />
              ))}
            </SubMenu>
          );
        }

        if (sidebarIsOpen) {
          return (
            <MenuItemLink
              to={{
                pathname: `/${menu.name}`,
                state: { _scrollToTop: true },
              }}
              primaryText={translate(`resources.${menu.name}.name`, {
                smart_count: 2,
              })}
              leftIcon={menu.icon && <menu.icon />}
            />
          );
        }
        return null;
      }),
    [menuLayout, state, sidebarIsOpen],
  );

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <Link to="/" className={classes.logoLink}>
        {open && <img alt="logo" className={classes.logo} src={Logo} />}
      </Link>
      <div className={classes.menuLinks}>
        <DashboardMenuItem />
        {React.Children.toArray(mappedMenuLayout)}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 28,
    marginBottom: theme.spacing(3),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 300,
  },
  closed: {
    width: 55,
  },
  submenu: {
    fontSize: 15,
  },
  logoLink: {
    height: 90,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  logo: {
    width: 120,
  },
  menuLinks: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default Menu;
