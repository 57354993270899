import React from 'react';
import { AdminContext, AdminUI, fetchUtils, Resource } from 'react-admin';
import crudProvider from 'ra-data-nestjsx-crud';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from './i18n/ru';
import { Layout, Login } from './layout';
import authProvider from './authProvider';
import customRoutes from './routes';
import themeReducer from './themeReducer';
import { ACCESS_TOKEN_STORE_KEY } from './constants/auth';
import jwtDecode from 'jwt-decode';
import { refreshToken } from './api/requests/refreshToken';
import { definitions } from './definitions';
import { mapResourceProps } from 'viewRenderers/mapResourceProps';
import { Ready } from './layout/Ready';
import { SnackbarProvider } from 'notistack';

const dataProvider = crudProvider(
  process.env.REACT_APP_API_URL,
  async (url, options) => {
    let token = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
    if (token) {
      const tokenPayload = jwtDecode(token) as {
        exp: number;
      };
      if (tokenPayload.exp * 1000 - Date.now() < 30 * 1000) {
        token = await refreshToken();
      }
    }
    return fetchUtils.fetchJson(url, {
      ...options,
      user: {
        authenticated: !!token,
        token: `Bearer ${token}`,
      },
    });
  },
) as any;
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const App = () => (
  <AdminContext
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    customReducers={{ theme: themeReducer }}
    authProvider={authProvider}
  >
    <SnackbarProvider>
      <AdminUI layout={Layout} loginPage={Login} ready={Ready}>
        {definitions.map((d) => (
          <Resource key={d.name} {...mapResourceProps(d, definitions)} />
        ))}
      </AdminUI>
    </SnackbarProvider>
  </AdminContext>
);
export default App;
