import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from 'ra-core';
import { FileData, FileSourceTypes } from '../../types/upload';
import classnames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    filePreview: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      position: 'absolute',
      bottom: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    progressNumber: {
      position: 'absolute',
      fontSize: 13,
    },
    removeButton: {},
    removeIcon: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'RaFileInputPreview' },
);

interface Props {
  className?: string;
  classes?: Record<string, any>;
  onRemove: () => void;
  file?: File;
  progress?: number;
  children: React.ReactNode;
  data: FileData;
  sourceType: FileSourceTypes;
}

const FileInputPreview = (props: Props) => {
  const {
    classes: classesOverride,
    className,
    onRemove,
    children,
    file,
    data,
    sourceType,
    progress,
    ...rest
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [isProgressShown, setProgressShown] = React.useState(false);
  React.useEffect(() => {
    if (progress && progress !== 100) {
      setProgressShown(true);
    }
    if (progress === 100) {
      setTimeout(() => {
        setProgressShown(false);
      }, 3000);
    }
  }, [progress]);

  return (
    <div className={classnames(classes.filePreview, className)} {...rest}>
      <IconButton
        className={classes.removeButton}
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
      >
        <RemoveCircle className={classes.removeIcon} />
      </IconButton>
      {children}
      {isProgressShown && (
        <div className={classes.progress}>
          <span className={classes.progressNumber}>{progress}%</span>
          <CircularProgress variant="determinate" value={progress} />
        </div>
      )}
    </div>
  );
};

FileInputPreview.defaultProps = {
  file: undefined,
};

export default FileInputPreview;
