import React from 'react';

export enum FieldDefinitionsEnum {
  STRING = 'string',
  JSON = 'json',
  SIMPLE_ARRAY = 'simple_array',
  JSON_ARRAY = 'json_array',
  TEXT = 'text',
  SELECT = 'select',
  IMAGE = 'image',
  FILE = 'file',
  MULTIPLE_IMAGES = 'multiple_image',
  MULTIPLE_FILES = 'multiple_files',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  REFERENCE = 'reference',
  MULTIPLE_REFERENCE = 'multiple_reference',
  URL = 'url',
  CUSTOM = 'custom',
}

export enum ViewTypesEnum {
  SHOW = 'show',
  EDIT = 'edit',
  CREATE = 'creat',
  LIST = 'list',
}

export enum SortOrdersEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RelationshipTypesEnum {
  ONE_TO_MANY = 'one_to_many',
}

export enum ActionTypesEnum {
  ON_ROW = 'on_row',
  // ON_LIST = 'on_list',
}

export interface FilterDefinition {
  enabled: boolean;
  alwaysOn: boolean;
}

export interface FieldReferenceDefinition {
  target: string;
  displayField: string;
}

export type FieldDefinitionStaticLayout = ({
  name?: string;
  type: FieldDefinitionsEnum;
} & Partial<FieldDefinition>)[];

export type FieldDefinitionLayout =
  | FieldDefinitionStaticLayout
  | ((record) => FieldDefinitionStaticLayout);

export interface FieldCustomComponents {
  input: any;
  field: any;
}

export interface FieldChoice {
  id: string;
  name: string;
}

export interface AdditionalActionDefinition {
  component: React.ElementType;
  type: ActionTypesEnum;
}

export interface FieldDefinition {
  name: string;
  required?: boolean;
  type: FieldDefinitionsEnum;
  editable?: boolean;
  layout?: FieldDefinitionLayout;
  customComponents?: FieldCustomComponents;
  reference?: FieldReferenceDefinition;
  choices?: FieldChoice[];
  defaultValue?: any;
  hidden?: Partial<Record<ViewTypesEnum, boolean>>;
  disabled?: Partial<
    Pick<
      Record<ViewTypesEnum, boolean>,
      ViewTypesEnum.CREATE | ViewTypesEnum.EDIT
    >
  >;
  filter?: FilterDefinition;
  visibilityCondition?: (value: any) => boolean;
}

export interface RelationshipDefinition {
  type: RelationshipTypesEnum;
  target: string;
  field: string;
}

export interface Definition {
  name: string;
  titleField: string;
  fields: FieldDefinition[];
  relationships?: RelationshipDefinition[];
  additionalActions?: AdditionalActionDefinition[];
  sort: {
    field: string;
    order: SortOrdersEnum;
  };
  validationSchema?: any;
}
