import * as React from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { FieldDefinitionLayout } from '../../types/definition';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  label: {
    fontSize: 14,
  },
  card: {
    marginTop: 20,
    marginBottom: 14,
  },
});

export const JsonInput = ({
  source,
  layout,
  mapper,
  record,
  resource,
}: {
  layout: FieldDefinitionLayout;
  mapper: any;
  source: string;
  record?: any;
  resource?: any;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const computedLayout = typeof layout === 'function' ? layout(record) : layout;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.label} color="textSecondary">
          {t(`resources.${resource}.fields.${source}`)}
        </Typography>
        {computedLayout.map((l) => {
          const name = `${source}.${l.name}`;
          return <div>{mapper(resource, { ...l, name })}</div>;
        })}
      </CardContent>
    </Card>
  );
};
