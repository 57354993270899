import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';
import {
  adminTaskStatusChoices,
  AdminTaskStatusesEnum,
} from './adminTask.definition';

const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  companyCategory: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  cityName: Yup.string().required(),
});

export const CallbackRequestDefinition: Definition = {
  name: 'callbackRequest',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'status',
      type: FieldDefinitionsEnum.SELECT,
      editable: true,
      choices: adminTaskStatusChoices,
      defaultValue: AdminTaskStatusesEnum.TODO,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'companyName',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'companyCategory',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'phoneNumber',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'cityName',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
