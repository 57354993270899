import {
  EditActionsProps,
  ListButton,
  ShowButton,
  TopToolbar,
} from 'react-admin';

export const EditActions = ({
  basePath,
  className,
  data,
  hasList,
  hasShow,
}: EditActionsProps) => (
  <TopToolbar className={className}>
    {hasList && <ListButton basePath={basePath} />}
    {hasShow && <ShowButton basePath={basePath} record={data} />}
  </TopToolbar>
);
