import { getClient } from '../client';

interface CreateCompanyFromAdminTaskResponse {
  id: string;
}

export const createCompanyFromAdminTask = async (record: any) => {
  const client = getClient();
  const response = await client.post<CreateCompanyFromAdminTaskResponse>(
    'company',
    {
      contactName: record.contactName,
      companyName: record.companyName,
      email: record.email,
      name: record.name,
      phoneNumbers: record.phoneNumbers,
      socials: record.socials,
    },
  );
  await client.patch(`adminTask/${record.id}`, {
    ...record,
    companyId: response.data.id,
  });
  return response.data;

  // return true;
};
