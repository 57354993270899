import * as React from 'react';
import { Time, WeekDaysEnum, WorkingHours } from './types';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Labeled, required, TextInput } from 'react-admin';
import { useInput, useTranslate } from 'ra-core';

const useStyles = makeStyles({
  label: {
    fontSize: 14,
  },
  day: {
    fontSize: 14,
  },
  inputHelp: {
    marginRight: 10,
    marginLeft: 10,
  },
  card: {
    marginTop: 20,
    marginBottom: 14,
  },
});

const dayKeys: {
  [key in keyof WorkingHours]: string;
} = {
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
};

type IProps = {
  source: string;
  record?: WorkingHours;
  resource?: string;
} & any;

const isDayPresent = (time?: Time) =>
  !!time && time.from !== undefined && time.to !== undefined;

export const WorkingHoursInput = ({
  record,
  source,
  resource,
  ...props
}: IProps) => {
  const classes = useStyles();
  const t = useTranslate();
  const {
    input: { onChange, value },
  } = useInput({ source, record, resource, ...props });
  const [checksState, setChecksState] = React.useState<{
    [key in WeekDaysEnum]: boolean;
  }>(
    record && Object.keys(record).length
      ? {
          monday: isDayPresent(record.workingHours.monday),
          tuesday: isDayPresent(record.workingHours.tuesday),
          wednesday: isDayPresent(record.workingHours.wednesday),
          thursday: isDayPresent(record.workingHours.thursday),
          friday: isDayPresent(record.workingHours.friday),
          saturday: isDayPresent(record.workingHours.saturday),
          sunday: isDayPresent(record.workingHours.sunday),
        }
      : {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
  );

  const onCheckChange = (dayKey: string) => {
    setChecksState({
      ...checksState,
      [dayKey]: !checksState[dayKey],
    });
    if (checksState[dayKey]) {
      const newValues = { ...value };
      delete newValues[dayKey];
      onChange(newValues);
    } else {
      const newValues = {
        ...value,
        [dayKey]: {},
      };
      onChange(newValues);
    }
  };

  React.useEffect(() => {
    if (!record || !Object.keys(record).length) {
      onChange({
        monday: {
          from: '10:00',
          to: '20:00',
        },
        tuesday: {
          from: '10:00',
          to: '20:00',
        },
        wednesday: {
          from: '10:00',
          to: '20:00',
        },
        thursday: {
          from: '10:00',
          to: '20:00',
        },
        friday: {
          from: '10:00',
          to: '20:00',
        },
      });
    }
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.label} color="textSecondary">
          {t(`resources.${resource}.fields.${source}`)}
        </Typography>
        {Object.entries(dayKeys).map(
          ([dayKey, dayName]: [keyof WorkingHours, string]) => (
            <Labeled key={dayKey} label={dayName} fullWidth>
              <Box display="flex" alignItems="center">
                <Box>
                  <Checkbox
                    onChange={() => onCheckChange(dayKey)}
                    checked={checksState[dayKey]}
                  />
                </Box>
                {checksState[dayKey] && (
                  <>
                    <Typography className={classes.inputHelp}>с</Typography>
                    <TextInput
                      label=" "
                      type="time"
                      source={`${source}.${dayKey}.from`}
                      isRequired
                      meta={{ touched: true, error: 'asdasd' }}
                      validate={required()}
                    />
                    <Typography className={classes.inputHelp}>до</Typography>
                    <TextInput
                      label=" "
                      type="time"
                      source={`${source}.${dayKey}.to`}
                      isRequired
                      meta={{ touched: true }}
                      validate={required()}
                    />
                  </>
                )}
              </Box>
            </Labeled>
          ),
        )}
      </CardContent>
    </Card>
  );
};
