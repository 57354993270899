import * as React from 'react';
import {
  Tab,
  BooleanField,
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  ReferenceManyField,
  Datagrid,
  EditButton,
  Button,
  Link,
  useTranslate,
  DeleteButton,
  ArrayField,
  SingleFieldList,
  SelectField,
  UrlField,
  ChipField,
} from 'react-admin';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import {
  FieldChoice,
  FieldCustomComponents,
  FieldDefinition,
  FieldDefinitionLayout,
  FieldDefinitionsEnum,
  FieldDefinitionStaticLayout,
  FieldReferenceDefinition,
  ViewTypesEnum,
} from '../types/definition';
import { memo, ReactElement } from 'react';
import { ShowTitle } from '../components/titles/ShowTitle';
import { ShowActions } from '../components/actions/ShowActions';
import { GeneratedViewOptions } from './types';
import SimpleChipField from '../components/SingleChipField';
import { JsonField } from '../components/JsonField';
import ImageField from '../components/ImageField';
import FileField from '../components/FileField';
import { checkAuthentication } from '../helpers/hooks/checkAuthentication';
import View from '../layout/View';

const mapFieldDefinitionToShowField = (
  resource: string,
  fieldDefinition: FieldDefinition,
): ReactElement => {
  switch (fieldDefinition.type) {
    case FieldDefinitionsEnum.TEXT:
    case FieldDefinitionsEnum.STRING: {
      return <TextField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.URL: {
      return <UrlField target="_blank" source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.SELECT: {
      const choices = fieldDefinition.choices as FieldChoice[];
      return <SelectField source={fieldDefinition.name} choices={choices} />;
    }
    case FieldDefinitionsEnum.DATE: {
      return <DateField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.DATETIME: {
      return <DateField showTime source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.BOOLEAN: {
      return <BooleanField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.MULTIPLE_IMAGES:
    case FieldDefinitionsEnum.IMAGE: {
      return <ImageField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.MULTIPLE_FILES:
    case FieldDefinitionsEnum.FILE: {
      return <FileField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.SIMPLE_ARRAY: {
      return (
        <ArrayField source={fieldDefinition.name}>
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.JSON_ARRAY: {
      const layout = fieldDefinition.layout as FieldDefinitionStaticLayout;
      return (
        <ArrayField source={fieldDefinition.name}>
          <Datagrid>
            {layout.map((l: any) => mapFieldDefinitionToShowField(resource, l))}
          </Datagrid>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.JSON: {
      const layout = fieldDefinition.layout as FieldDefinitionLayout;
      return (
        <JsonField
          resource={resource}
          source={fieldDefinition.name}
          layout={layout}
          mapper={mapFieldDefinitionToShowField}
        />
      );
    }
    case FieldDefinitionsEnum.REFERENCE: {
      const reference = fieldDefinition.reference as FieldReferenceDefinition;
      return (
        <ReferenceField
          reference={reference.target}
          source={fieldDefinition.name}
          link="show"
        >
          <TextField source={reference.displayField} />
        </ReferenceField>
      );
    }
    case FieldDefinitionsEnum.MULTIPLE_REFERENCE: {
      const reference = fieldDefinition.reference as FieldReferenceDefinition;
      return (
        <ArrayField source={fieldDefinition.name}>
          <SingleFieldList linkType={false}>
            <ChipField source={reference.displayField} />
          </SingleFieldList>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.CUSTOM: {
      const customComponents =
        fieldDefinition.customComponents as FieldCustomComponents;
      const Field = customComponents.field;
      return <Field source={fieldDefinition.name} />;
    }
  }
  return null;
};

const AddNewButton = memo(
  (props: {
    options: {
      resource: string;
      referenceField: string;
      id: string | undefined;
    };
  }) => {
    const { resource, referenceField, id } = props.options;
    const t = useTranslate();
    return (
      <Button
        component={Link}
        to={{
          pathname: `/${resource}/create`,
          state: { record: { [referenceField]: id } },
        }}
        label={t('showTab.create', {
          name: t(`resources.${resource}.name`, { smart_count: 1 }),
        })}
      >
        <ChatBubbleIcon />
      </Button>
    );
  },
);

const ShowView = (props: ShowProps) => {
  checkAuthentication();
  const translate = useTranslate();
  const { fields, relationships, definitions, titleField } =
    props.options as GeneratedViewOptions;
  const mappedFields = React.useMemo(
    () =>
      fields
        .filter((field) => !field.hidden?.[ViewTypesEnum.SHOW])
        .map((field) => mapFieldDefinitionToShowField(props.resource, field))
        .filter((field) => !!field),
    [fields],
  );

  const mappedRelationships = React.useMemo(
    () =>
      relationships &&
      relationships.map((relation) => {
        const definition = definitions.find((d) => relation.target === d.name);
        if (definition) {
          return (
            <Tab
              label={translate(`resources.${definition.name}.name`, {
                smart_count: 2,
              })}
              path={definition.name}
            >
              <ReferenceManyField
                label=""
                reference={definition.name}
                target={relation.field}
              >
                <Datagrid rowClick="show">
                  {definition.fields.map(
                    (field) =>
                      !field.hidden?.[ViewTypesEnum.LIST] &&
                      mapFieldDefinitionToShowField(props.resource, field),
                  )}
                  <EditButton />
                  <DeleteButton />
                </Datagrid>
              </ReferenceManyField>
              <AddNewButton
                options={{
                  resource: relation.target,
                  referenceField: relation.field,
                  id: props.id,
                }}
              />
            </Tab>
          );
        }
      }),
    [relationships],
  );

  if (relationships && relationships.length) {
    return (
      <View>
        <Show
          title={
            <ShowTitle titleField={titleField} resource={props.resource} />
          }
          actions={<ShowActions />}
          {...props}
        >
          <TabbedShowLayout>
            <Tab label={translate('showTab.summary')}>
              {React.Children.toArray(mappedFields)}
            </Tab>
            {React.Children.toArray(mappedRelationships)}
          </TabbedShowLayout>
        </Show>
      </View>
    );
  }

  return (
    <View>
      <Show
        title={<ShowTitle titleField={titleField} resource={props.resource} />}
        actions={<ShowActions />}
        {...props}
      >
        <SimpleShowLayout>
          {React.Children.toArray(mappedFields)}
        </SimpleShowLayout>
      </Show>
    </View>
  );
};

export default ShowView;
