import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
  SingleFieldList,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';
import * as React from 'react';
import { ReactElement } from 'react';
import {
  AdditionalActionDefinition,
  ActionTypesEnum,
  FieldChoice,
  FieldCustomComponents,
  FieldDefinition,
  FieldDefinitionsEnum,
  FieldDefinitionStaticLayout,
  FieldReferenceDefinition,
  ViewTypesEnum,
} from '../types/definition';
import { GeneratedViewOptions } from './types';
import SimpleChipField from '../components/SingleChipField';
import ImageField from '../components/ImageField';
import FileField from '../components/FileField';
import ListActions from '../components/actions/ListActions';
import View from '../layout/View';
import { checkAuthentication } from '../helpers/hooks/checkAuthentication';
import { makeStyles } from '@material-ui/core/styles';
import { ListTitle } from '../components/titles/ListTitle';
import { Button, Menu } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';

const mapFieldDefinitionToListField = (
  resource: string,
  fieldDefinition: FieldDefinition,
): ReactElement => {
  switch (fieldDefinition.type) {
    case FieldDefinitionsEnum.TEXT:
    case FieldDefinitionsEnum.STRING: {
      return <TextField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.URL: {
      return <UrlField target="_blank" source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.SELECT: {
      const choices = fieldDefinition.choices as FieldChoice[];
      return <SelectField source={fieldDefinition.name} choices={choices} />;
    }
    case FieldDefinitionsEnum.DATE: {
      return <DateField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.DATETIME: {
      return <DateField showTime source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.BOOLEAN: {
      return <BooleanField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.IMAGE: {
      return <ImageField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.FILE: {
      return <FileField source={fieldDefinition.name} />;
    }
    case FieldDefinitionsEnum.SIMPLE_ARRAY: {
      return (
        <ArrayField source={fieldDefinition.name}>
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.JSON_ARRAY: {
      const layout = fieldDefinition.layout as FieldDefinitionStaticLayout;
      return (
        <ArrayField source={fieldDefinition.name}>
          <Datagrid>
            {layout.map((l: any) => mapFieldDefinitionToListField(resource, l))}
          </Datagrid>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.REFERENCE: {
      const reference = fieldDefinition.reference as FieldReferenceDefinition;
      return (
        <ReferenceField
          reference={reference.target}
          source={fieldDefinition.name}
          link="show"
        >
          <TextField source={reference.displayField} />
        </ReferenceField>
      );
    }
    case FieldDefinitionsEnum.MULTIPLE_REFERENCE: {
      const reference = fieldDefinition.reference as FieldReferenceDefinition;
      return (
        <ArrayField source={fieldDefinition.name}>
          <SingleFieldList linkType={false}>
            <ChipField source={reference.displayField} />
          </SingleFieldList>
        </ArrayField>
      );
    }
    case FieldDefinitionsEnum.CUSTOM: {
      const customComponents =
        fieldDefinition.customComponents as FieldCustomComponents;
      const Field = customComponents.field;
      return <Field source={fieldDefinition.name} />;
    }
  }
  return null;
};

const mapFieldDefinitionsToListFilters = (
  fieldDefinitions: FieldDefinition[],
): ReactElement[] => {
  return fieldDefinitions
    .filter((field) => field.filter && field.filter.enabled)
    .map((field) => {
      switch (field.type) {
        case FieldDefinitionsEnum.URL:
        case FieldDefinitionsEnum.TEXT:
        case FieldDefinitionsEnum.STRING: {
          return (
            <TextInput
              resettable
              source={field.name}
              alwaysOn={field.filter?.alwaysOn}
            />
          );
        }
        case FieldDefinitionsEnum.SELECT: {
          const choices = field.choices as FieldChoice[];
          return <SelectInput source={field.name} choices={choices} />;
        }
        case FieldDefinitionsEnum.DATETIME:
        case FieldDefinitionsEnum.DATE: {
          return (
            <DateInput source={field.name} alwaysOn={field.filter?.alwaysOn} />
          );
        }
        case FieldDefinitionsEnum.BOOLEAN: {
          return (
            <BooleanInput
              source={field.name}
              alwaysOn={field.filter?.alwaysOn}
            />
          );
        }
        case FieldDefinitionsEnum.REFERENCE: {
          const reference = field.reference as FieldReferenceDefinition;
          return (
            <ReferenceInput
              reference={reference.target}
              source={field.name}
              alwaysOn={field.filter?.alwaysOn}
              filterToQuery={(searchText) =>
                !!searchText && { [reference.displayField]: searchText || '' }
              }
            >
              <AutocompleteInput
                resettable
                optionText={reference.displayField}
              />
            </ReferenceInput>
          );
        }
        case FieldDefinitionsEnum.CUSTOM: {
          const customComponents =
            field.customComponents as FieldCustomComponents;
          const Input = customComponents.input;
          return <Input source={field.name} />;
        }
      }
      return null;
    });
};

const ActionColumn = (props) => {
  const additionalActions = props.additionalActions as
    | AdditionalActionDefinition[]
    | undefined;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.actions}>
      <EditButton {...props} />
      <ShowButton {...props} />
      {additionalActions && (
        <>
          <Button
            startIcon={<Settings />}
            color="primary"
            size="small"
            onClick={handleClick}
          >
            Действия
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {additionalActions
              .filter((action) => action.type === ActionTypesEnum.ON_ROW)
              .map((action) => {
                const Component = action.component;
                return <Component onClick={handleClose} {...props} />;
              })}
          </Menu>
        </>
      )}
    </div>
  );
};

export const ListView = (props: ListProps) => {
  checkAuthentication();
  const { fields, sort, additionalActions } =
    props.options as GeneratedViewOptions;
  const mappedFilters = React.useMemo(
    () => mapFieldDefinitionsToListFilters(fields).filter((field) => !!field),
    [fields],
  );
  const mappedFields = React.useMemo(
    () =>
      fields
        .filter((field) => !field.hidden?.[ViewTypesEnum.LIST])
        .map((field) => mapFieldDefinitionToListField(props.resource, field))
        .filter((field) => !!field),
    [fields],
  );
  return (
    <View>
      <List
        {...props}
        title={<ListTitle resource={props.resource} />}
        sort={sort}
        filters={mappedFilters}
        actions={<ListActions />}
        component="div"
      >
        <Datagrid>
          {React.Children.toArray(mappedFields)}
          <ActionColumn additionalActions={additionalActions} />
        </Datagrid>
      </List>
    </View>
  );
};

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
