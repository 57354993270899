import { AdPublicationDefinition } from './adPublication.definition';
import { BannerDefinition } from './banner.definition';
import { BannerRequestDefinition } from './bannerRequest.definition';
import { CallbackRequestDefinition } from './callbackRequest.definition';
import { CategoryDefinition } from './category.definition';
import { CityDefinition } from './city.definition';
import { CompanyDefinition } from './company.definition';
import { ExternalBannerRequestDefinition } from './externalBannerRequest.definition';
import { FeedbackDefinition } from './feedback.definition';
import { PaymentDefinition } from './payment.definition';
import { PromotionDefinition } from './promotion.definition';
import { PromotionRequestDefinition } from './promotionRequest.definition';
import { PublicationDefinition } from './publication.definition';
import { PublicationRequestDefinition } from './publicationRequest.definition';
import { RecommendationDefinition } from './recommendation.definition';
import { RecommendationRequestDefinition } from './recommendationRequest.definition';
import { SubcategoryDefinition } from './subcategory.definition';
import { AdminTaskHistoryDefinition } from './adminTaskHistory.definition';
import { AdminTaskDefinition } from './adminTask.definition';

export const definitions = [
  AdminTaskDefinition,
  AdminTaskHistoryDefinition,
  AdPublicationDefinition,
  BannerDefinition,
  BannerRequestDefinition,
  CallbackRequestDefinition,
  CategoryDefinition,
  CityDefinition,
  CompanyDefinition,
  ExternalBannerRequestDefinition,
  FeedbackDefinition,
  PaymentDefinition,
  PromotionDefinition,
  PromotionRequestDefinition,
  PublicationDefinition,
  PublicationRequestDefinition,
  RecommendationDefinition,
  RecommendationRequestDefinition,
  SubcategoryDefinition,
];
