import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import { darkTheme, lightTheme } from './themes';
import { AppState } from '../types';
import Sidebar from './Sidebar';
import Menu from './Menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: { paddingTop: 12 },
});

export default (props: LayoutProps) => {
  const classes = useStyles();
  const theme = useSelector((state: AppState) =>
    state.theme === 'dark' ? darkTheme : lightTheme,
  );
  return (
    <Layout
      {...props}
      appBar={AppBar}
      menu={Menu}
      className={classes.root}
      theme={theme}
      sidebar={Sidebar as any}
    />
  );
};
