import React from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { useApiCall } from 'api/useApiCall';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { restoreAdminTaskFromHistory } from 'api/requests/restoreAdminTaskFromHistory';

interface IProps {
  record: any;
  onClick: () => void;
}

export const RestoreAdminTaskAction = ({ record, onClick }: IProps) => {
  const snackBarKeyRef = React.useRef<SnackbarKey>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const styles = useStyles();
  const handleClick = () => {
    snackBarKeyRef.current = enqueueSnackbar('Восстановление задачи...', {
      persist: true,
      variant: 'info',
    });

    call(record);
    onClick();
  };

  const { call } = useApiCall<typeof restoreAdminTaskFromHistory>(
    restoreAdminTaskFromHistory,
    {
      onSuccess: (data) => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Задача восстановлена', {
          variant: 'success',
          action: () => (
            <Link className={styles.link} to={`/adminTask/${data.id}`}>
              <Button color="default" size="small">
                Перейти
              </Button>
            </Link>
          ),
        });
      },
      onError: () => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Произошла ошибка при восстановлении задачи', {
          variant: 'error',
        });
      },
    },
  );

  return <MenuItem onClick={handleClick}>Восстановить задачу</MenuItem>;
};

const useStyles = makeStyles({
  link: {
    textDecorationColor: '#000',
  },
});
