import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';
import { WorkingHoursInput } from '../components/WorkingHoursInput';
import { WorkingHoursField } from 'components/WorkingHoursField';
import { WeekDaysEnum } from '../constants/dates';
import {
  adminTaskStatusChoices,
  AdminTaskStatusesEnum,
} from './adminTask.definition';

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  status: Yup.string().required(),
  contactName: Yup.string().nullable(),
  email: Yup.string().required(),
  phoneNumbers: Yup.array().of(Yup.string().required()).min(1).required(),
  subcategoryId: Yup.string().required(),
  photos: Yup.array().required().min(1),
  addressDetails: Yup.object().shape({
    street: Yup.string().required(),
    house: Yup.string().required(),
    building: Yup.string(),
    apartment: Yup.string(),
  }),
  workingHours: Yup.object()
    .shape(
      Object.values(WeekDaysEnum).reduce((acc, dayKey) => {
        acc[dayKey] = Yup.object()
          .shape({
            to: Yup.mixed().required(),
            from: Yup.mixed().required(),
          })
          .default(undefined)
          .nullable();
        return acc;
      }, {}),
    )
    .default(undefined)
    .isNotEmpty(),
  description: Yup.string().required(),
  cityName: Yup.string().required(),
});

export const PublicationRequestDefinition: Definition = {
  name: 'publicationRequest',
  titleField: 'title',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'status',
      type: FieldDefinitionsEnum.SELECT,
      editable: true,
      choices: adminTaskStatusChoices,
      defaultValue: AdminTaskStatusesEnum.TODO,
    },
    {
      name: 'contactName',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'email',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'phoneNumbers',
      required: true,
      type: FieldDefinitionsEnum.SIMPLE_ARRAY,
      layout: [
        {
          type: FieldDefinitionsEnum.STRING,
        },
      ],
    },
    {
      name: 'title',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'description',
      required: true,
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'companyName',
      required: true,
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'companyLogo',
      type: FieldDefinitionsEnum.IMAGE,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'addressDetails',
      required: true,
      type: FieldDefinitionsEnum.JSON,
      layout: [
        {
          name: 'street',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'house',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'building',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'apartment',
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'website',
      type: FieldDefinitionsEnum.URL,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'socials',
      type: FieldDefinitionsEnum.JSON,
      layout: [
        {
          name: 'viber',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'telegram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'instagram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'whatsapp',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'facebook',
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'workingHours',
      type: FieldDefinitionsEnum.CUSTOM,
      customComponents: {
        input: WorkingHoursInput,
        field: WorkingHoursField,
      },
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'photos',
      required: true,
      type: FieldDefinitionsEnum.MULTIPLE_IMAGES,
    },
    {
      name: 'subcategoryId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'subcategory',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'cityName',
      required: true,
      type: FieldDefinitionsEnum.URL,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'cityId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'city',
        displayField: 'id',
      },
    },
    {
      name: 'companyId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
    },
    {
      name: 'publicationId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'publication',
        displayField: 'id',
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
