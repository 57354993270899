import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  view: { marginBottom: 16 },
});

const View = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: any;
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h6"
        className={classes.view}
        color="inherit"
        id="react-admin-title"
      >
        {title}
      </Typography>
      {children}
    </>
  );
};

export default View;
