import Yup from 'yup-custom';
import {
  ActionTypesEnum,
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';
import { WorkingHoursInput } from '../components/WorkingHoursInput';
import { WorkingHoursField } from 'components/WorkingHoursField';
import { WeekDaysEnum } from '../constants/dates';
import { CreateCompanyAction } from '../actions/adminTask/CreateCompanyAction';
import { CreatePublicationAction } from '../actions/adminTask/CreatePublicationAction';

export enum AdminTaskStatusesEnum {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELED = 'canceled',
}

export const adminTaskStatusChoices = [
  {
    id: AdminTaskStatusesEnum.TODO,
    name: 'Создан',
  },
  {
    id: AdminTaskStatusesEnum.IN_PROGRESS,
    name: 'В прогрессе',
  },
  {
    id: AdminTaskStatusesEnum.CANCELED,
    name: 'Отменен',
  },
  {
    id: AdminTaskStatusesEnum.DONE,
    name: 'Готово',
  },
];

const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  title: Yup.string().nullable(),
  contactName: Yup.string().nullable(),
  companyName: Yup.string().nullable(),
  companyId: Yup.string().nullable(),
  email: Yup.string().nullable(),
  phoneNumbers: Yup.array().of(Yup.string()).nullable(),
  subcategoryId: Yup.string().nullable(),
  publicationId: Yup.string().nullable(),
  photos: Yup.array().nullable(),
  addressDetails: Yup.object()
    .shape({
      street: Yup.string(),
      house: Yup.string(),
      building: Yup.string(),
      apartment: Yup.string(),
    })
    .nullable(),
  workingHours: Yup.object()
    .shape(
      Object.values(WeekDaysEnum).reduce((acc, dayKey) => {
        acc[dayKey] = Yup.object()
          .shape({
            to: Yup.mixed().required(),
            from: Yup.mixed().required(),
          })
          .default(undefined)
          .nullable();
        return acc;
      }, {}),
    )
    .default(undefined),
  description: Yup.string().nullable(),
  cityName: Yup.string().nullable(),
  cityId: Yup.string().nullable(),
  comments: Yup.string().nullable(),
});

export const AdminTaskDefinition: Definition = {
  name: 'adminTask',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  additionalActions: [
    {
      type: ActionTypesEnum.ON_ROW,
      component: CreateCompanyAction,
    },
    {
      type: ActionTypesEnum.ON_ROW,
      component: CreatePublicationAction,
    },
  ],
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'status',
      type: FieldDefinitionsEnum.SELECT,
      editable: true,
      choices: adminTaskStatusChoices,
      defaultValue: AdminTaskStatusesEnum.TODO,
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'contactName',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'email',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'phoneNumbers',
      type: FieldDefinitionsEnum.SIMPLE_ARRAY,
      layout: [
        {
          type: FieldDefinitionsEnum.STRING,
        },
      ],
    },
    {
      name: 'title',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'description',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'companyName',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'addressDetails',
      type: FieldDefinitionsEnum.JSON,
      layout: [
        {
          name: 'street',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'house',
          required: true,
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'building',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'apartment',
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'website',
      type: FieldDefinitionsEnum.URL,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'socials',
      type: FieldDefinitionsEnum.JSON,
      layout: [
        {
          name: 'viber',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'telegram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'instagram',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'whatsapp',
          type: FieldDefinitionsEnum.STRING,
        },
        {
          name: 'facebook',
          type: FieldDefinitionsEnum.STRING,
        },
      ],
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'workingHours',
      type: FieldDefinitionsEnum.CUSTOM,
      customComponents: {
        input: WorkingHoursInput,
        field: WorkingHoursField,
      },
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'photos',
      type: FieldDefinitionsEnum.MULTIPLE_IMAGES,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'subcategoryId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'subcategory',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'cityId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'city',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'companyId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'publicationId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'publication',
        displayField: 'id',
      },
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
