import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import { sanitizeFieldRestProps } from 'react-admin';
import { getUrlFromFileData } from '../../helpers/upload';
import {
  fieldPropTypes,
  FileSourceTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from '../../types/upload';

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  },
  { name: 'RaImageField' },
);

interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
  source?: string;
  src?: string;
  title?: string;
  classes?: Record<string, any>;
}

const ImageField = (props: ImageFieldProps) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    title,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const imageUrls = React.useRef(
    Array.isArray(record)
      ? record.map((r) =>
          r.sourceType === FileSourceTypes.INPUT
            ? URL.createObjectURL(r.file)
            : getUrlFromFileData(r.data),
        )
      : record.sourceType === FileSourceTypes.INPUT
      ? URL.createObjectURL(record.file)
      : getUrlFromFileData(record.data),
  );

  React.useEffect(() => {
    return () => {
      Array.isArray(record)
        ? record.forEach((r, i) => {
            if (r.sourceType === FileSourceTypes.INPUT) {
              URL.revokeObjectURL(imageUrls.current[i]);
            }
          })
        : URL.revokeObjectURL(imageUrls.current as string);
    };
  }, [record]);

  const classes = useStyles(props);
  if (!record) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(record)) {
    return (
      <ul
        className={classnames(classes.list, className)}
        {...sanitizeFieldRestProps(rest)}
      >
        {record.map((file, index) => {
          const fileTitleValue = (title && get(file, title)) || title;
          return (
            <li key={index}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={imageUrls.current[index]}
                className={classes.image}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = (title && get(record, title)) || title;

  return (
    <div className={className} {...sanitizeFieldRestProps(rest)}>
      <img
        title={titleValue}
        alt={titleValue}
        src={imageUrls.current as string}
        className={classes.image}
      />
    </div>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
ImageField.displayName = 'ImageField';

ImageField.defaultProps = {
  addLabel: true,
};

ImageField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
};

export default ImageField;
