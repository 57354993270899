import { AuthProvider } from 'react-admin';
import { getClient } from './api/client';
import { ACCESS_TOKEN_STORE_KEY } from './constants/auth';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await getClient().post<{ accessToken: string }>(
      'auth/login',
      {
        email: username,
        password,
      },
    );
    localStorage.setItem(ACCESS_TOKEN_STORE_KEY, response.data.accessToken);
  },
  logout: async () => {
    await getClient().get('auth/logout');
    localStorage.removeItem('accessToken');
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      localStorage.removeItem(ACCESS_TOKEN_STORE_KEY);
      return Promise.reject({ redirectTo: '/login', logoutUser: false });
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
    if (!accessToken) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: async () => {
    const response = await getClient().get<{
      id: string;
      firstName: string;
      lastName: string;
    }>('user/current');
    return {
      id: response.data.id,
      fullName: `${response.data.firstName} ${response.data.lastName}`,
    };
  },
};

export default authProvider;
