import { getClient } from '../client';
import { FileData } from '../../types/upload';
import { copyFile } from '../aws/copyFile';

interface CreatePublicationFromAdminTaskResponse {
  id: string;
}

export const createPublicationFromAdminTask = async (record: any) => {
  const client = getClient();

  const uploadedPhotos = await Promise.all(
    record?.photos?.map(async (photoObject: FileData) => {
      return await copyFile(photoObject);
    }),
  );

  const response = await client.post<CreatePublicationFromAdminTaskResponse>(
    'publication',
    {
      title: record.title,
      email: record.email,
      phoneNumbers: record.phoneNumbers,
      subcategoryId: record.subcategoryId,
      photos: uploadedPhotos.length ? uploadedPhotos : undefined,
      addressDetails: record.addressDetails,
      workingHours: record.workingHours,
      description: record.description,
      socials: record.socials,
      cityId: record.cityId,
      companyId: record.companyId,
    },
  );
  await client.patch(`adminTask/${record.id}`, {
    ...record,
    publicationId: response.data.id,
  });

  return response.data;
};
