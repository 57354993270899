import Yup from 'yup-custom';
import {
  Definition,
  FieldDefinitionsEnum,
  SortOrdersEnum,
  ViewTypesEnum,
} from '../types/definition';

const bannerTypeChoices = [
  {
    id: 'internal',
    name: 'Внутренний',
  },
  {
    id: 'external',
    name: 'Внешний',
  },
];

const validationSchema = Yup.object().shape({
  mainPhoto: Yup.mixed().required(),
  sidePhoto: Yup.mixed().required(),
  type: Yup.string().required(),
  publicationId: Yup.lazy((value, options) => {
    if (options.parent.type === 'internal') {
      return Yup.string().required();
    }
    return Yup.string().nullable();
  }),
  externalLink: Yup.lazy((value, options) => {
    if (options.parent.type === 'external') {
      return Yup.string().required();
    }
    return Yup.string().nullable();
  }),
  cityId: Yup.string().required(),
  companyId: Yup.string().required(),
});

export const BannerDefinition: Definition = {
  name: 'banner',
  titleField: 'id',
  sort: {
    field: 'id',
    order: SortOrdersEnum.ASC,
  },
  validationSchema,
  fields: [
    {
      name: 'id',
      type: FieldDefinitionsEnum.STRING,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
        [ViewTypesEnum.CREATE]: true,
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'type',
      required: true,
      type: FieldDefinitionsEnum.SELECT,
      choices: bannerTypeChoices,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'publicationId',
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'publication',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
      visibilityCondition: (record) => record.type === 'internal',
    },
    {
      name: 'externalLink',
      type: FieldDefinitionsEnum.STRING,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
      visibilityCondition: (record) => record.type === 'external',
    },
    {
      name: 'cityId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'city',
        displayField: 'name',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'companyId',
      required: true,
      type: FieldDefinitionsEnum.REFERENCE,
      reference: {
        target: 'company',
        displayField: 'id',
      },
      filter: {
        enabled: true,
        alwaysOn: true,
      },
    },
    {
      name: 'mainPhoto',
      required: true,
      type: FieldDefinitionsEnum.IMAGE,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'sidePhoto',
      required: true,
      type: FieldDefinitionsEnum.IMAGE,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'comments',
      type: FieldDefinitionsEnum.TEXT,
      hidden: {
        [ViewTypesEnum.LIST]: true,
      },
    },
    {
      name: 'isActive',
      type: FieldDefinitionsEnum.BOOLEAN,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'activatedAt',
      type: FieldDefinitionsEnum.DATE,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'activatedTill',
      type: FieldDefinitionsEnum.DATE,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'createdAt',
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
      type: FieldDefinitionsEnum.DATETIME,
      filter: {
        enabled: true,
        alwaysOn: false,
      },
    },
    {
      name: 'updatedAt',
      type: FieldDefinitionsEnum.DATETIME,
      disabled: {
        [ViewTypesEnum.EDIT]: true,
      },
      hidden: {
        [ViewTypesEnum.CREATE]: true,
      },
    },
  ],
};
