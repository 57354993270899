import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Это обязательное поле',
    isNotEmpty: 'Это поле не должно быть пустым',
    notType: 'Это поле может содержать только цифры',
  },
  object: {
    isNotEmpty: 'Это поле не должно быть пустым',
  },
});
