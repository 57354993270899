import * as Yup from 'yup';

Yup.addMethod(Yup.object, 'isNotEmpty', function () {
  return this.test({
    name: 'isNotEmpty',
    message: 'Хотя бы одно значение должно быть заполнено',
    test: function (value) {
      return value && Object.keys(value).length !== 0;
    },
  });
});
