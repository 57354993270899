import { getClient } from '../client';
import { ACCESS_TOKEN_STORE_KEY } from 'constants/auth';

interface RefreshTokenResponse {
  accessToken: string;
}

export const refreshToken = async (): Promise<string> => {
  const client = getClient();
  try {
    const response = await client.get<RefreshTokenResponse>('auth/refresh');
    const { accessToken } = response.data;
    localStorage.setItem(ACCESS_TOKEN_STORE_KEY, accessToken);

    return accessToken;
  } catch (e) {}
};
