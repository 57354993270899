import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import { sanitizeFieldRestProps } from 'react-admin';
import {
  fieldPropTypes,
  FileData,
  InjectedFieldProps,
  PublicFieldProps,
} from '../../types/upload';

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  },
  { name: 'RaImageField' },
);

const getUrlFromFileData = (data: FileData) =>
  `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${data.bucketKey}`;

export interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
  source: string;
  src?: string;
  title?: string;
  classes?: Record<string, any>;
}

const ImageField = (props: ImageFieldProps) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    title,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);

  const classes = useStyles(props);
  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={classnames(classes.list, className)}
        {...sanitizeFieldRestProps(rest)}
      >
        {sourceValue.map((file, index) => {
          const fileTitleValue = (title && get(file, title)) || title;
          const srcValue = getUrlFromFileData(file);

          return (
            <li key={index}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={srcValue}
                className={classes.image}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = (title && get(record, title)) || title;
  const srcValue = getUrlFromFileData(sourceValue);

  return (
    <div className={className} {...sanitizeFieldRestProps(rest)}>
      <img
        title={titleValue}
        alt={titleValue}
        src={srcValue}
        className={classes.image}
      />
    </div>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
ImageField.displayName = 'ImageField';

ImageField.defaultProps = {
  addLabel: true,
};

ImageField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
};

export default ImageField;
