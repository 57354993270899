import { Menu } from './types/menu';
import RequestIcon from '@material-ui/icons/AssignmentLate';
import ContentIcon from '@material-ui/icons/Assignment';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
// import CategoryIcon from '@material-ui/icons/Bookmark';
// import LocationIcon from '@material-ui/icons/Room';
// import PaymentIcon from '@material-ui/icons/AttachMoney';
// import CompanyIcon from '@material-ui/icons/Business';
// import FeedbackIcon from '@material-ui/icons/Feedback';

export const menuLayout: Menu[] = [
  {
    name: 'requests',
    icon: RequestIcon,
    submenu: [
      {
        name: 'callbackRequest',
      },
      {
        name: 'bannerRequest',
      },
      {
        name: 'externalBannerRequest',
      },
      {
        name: 'promotionRequest',
      },
      {
        name: 'recommendationRequest',
      },
      {
        name: 'publicationRequest',
      },
    ],
  },
  {
    name: 'content',
    icon: ContentIcon,
    submenu: [
      {
        name: 'banner',
      },
      {
        name: 'adPublication',
      },
      {
        name: 'promotion',
      },
      {
        name: 'recommendation',
      },
      {
        name: 'publication',
      },
    ],
  },
  {
    name: 'admin',
    icon: AdminIcon,
    submenu: [
      {
        name: 'adminTask',
      },
      {
        name: 'adminTaskHistory',
      },
    ],
  },
  {
    name: 'category',
  },
  {
    name: 'subcategory',
  },
  {
    name: 'city',
  },
  {
    name: 'payment',
  },
  {
    name: 'company',
  },
  {
    name: 'feedback',
  },
];
