import * as React from 'react';
import { WeekDaysEnum, WorkingHours } from './types';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Labeled, TextField } from 'react-admin';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  label: {
    fontSize: 14,
  },
  day: {
    fontSize: 14,
  },
  from: {
    marginRight: 10,
  },
  to: {
    marginRight: 10,
    marginLeft: 10,
  },
  time: {
    fontSize: 14,
  },
  card: {
    marginTop: 20,
    marginBottom: 14,
  },
});

const dayKeys: {
  [key in keyof WorkingHours]: string;
} = {
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
};

type IProps = {
  source: string;
  record?: WorkingHours;
  resource?: string;
};

export const WorkingHoursField = ({ resource, record, source }: IProps) => {
  const classes = useStyles();
  const t = useTranslate();
  const [checksState] = React.useState<{
    [key in WeekDaysEnum]: boolean;
  }>(
    record[source]
      ? {
          monday:
            !!record[source].monday &&
            !!record[source].monday.to &&
            !!record[source].monday.from,
          tuesday:
            !!record[source].tuesday &&
            !!record[source].tuesday.to &&
            !!record[source].tuesday.from,
          wednesday:
            !!record[source].wednesday &&
            !!record[source].wednesday.to &&
            !!record[source].wednesday.from,
          thursday:
            !!record[source].thursday &&
            !!record[source].thursday.to &&
            !!record[source].thursday.from,
          friday:
            !!record[source].friday &&
            !!record[source].friday.to &&
            !!record[source].friday.from,
          saturday:
            !!record[source].saturday &&
            !!record[source].saturday.to &&
            !!record[source].saturday.from,
          sunday:
            !!record[source].sunday &&
            !!record[source].sunday.to &&
            !!record[source].sunday.from,
        }
      : {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.label} color="textSecondary">
          {t(`resources.${resource}.fields.${source}`)}
        </Typography>
        {Object.entries(dayKeys).map(
          ([dayKey, dayName]: [keyof WorkingHours, string]) => (
            <Labeled label={dayName} fullWidth={true}>
              <Box alignItems="center" display="flex">
                {!checksState[dayKey] && (
                  <Typography className={classes.time}>Не работает</Typography>
                )}
                {checksState[dayKey] && (
                  <>
                    <Typography className={classes.from}>с</Typography>
                    <TextField
                      className={classes.time}
                      source={`${source}.${dayKey}.from`}
                    />
                    <Typography className={classes.to}>до</Typography>
                    <TextField
                      className={classes.time}
                      source={`${source}.${dayKey}.to`}
                    />
                  </>
                )}
              </Box>
            </Labeled>
          ),
        )}
      </CardContent>
    </Card>
  );
};
