import React from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { useApiCall } from 'api/useApiCall';
import { createCompanyFromAdminTask } from 'api/requests/createCompanyFromAdminTask';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { validationSchema } from 'definitions/company.definition';

interface IProps {
  record: any;
  onClick: () => void;
}

export const CreateCompanyAction = ({ record, onClick }: IProps) => {
  const snackBarKeyRef = React.useRef<SnackbarKey>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const styles = useStyles();
  const handleClick = () => {
    try {
      validationSchema.validateSync(record);

      snackBarKeyRef.current = enqueueSnackbar('Создание организации...', {
        persist: true,
        variant: 'info',
      });

      call(record);
    } catch (err) {
      if (err?.errors?.length) {
        enqueueSnackbar('Недостаточно полей чтобы создать организацию', {
          variant: 'error',
        });
      }
    }
    onClick();
  };

  const { call } = useApiCall<typeof createCompanyFromAdminTask>(
    createCompanyFromAdminTask,
    {
      onSuccess: (data) => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Организация создана', {
          variant: 'success',
          action: () => (
            <Link className={styles.companyLink} to={`/company/${data.id}`}>
              <Button color="default" size="small">
                Перейти
              </Button>
            </Link>
          ),
        });
      },
      onError: () => {
        closeSnackbar(snackBarKeyRef.current);
        enqueueSnackbar('Произошла ошибка при создании организации', {
          variant: 'error',
        });
      },
    },
  );

  return <MenuItem onClick={handleClick}>Создать организацию</MenuItem>;
};

const useStyles = makeStyles({
  companyLink: {
    textDecorationColor: '#000',
  },
});
