import * as React from 'react';
import { AppBar, ReduxState, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logo: {
    height: 40,
    marginRight: 20,
  },
  bar: {
    width: 'calc(100vw - 300px)',
    transition: 'width .2s',
  },
  spacer: {
    flex: 1,
  },
  open: {
    width: 'calc(100vw - 300px)',
  },
  closed: {
    width: 'calc(100vw - 56px)',
  },
});

// const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
//   const translate = useTranslate();
//   return (
//     <MenuItemLink
//       ref={ref}
//       to="/configuration"
//       primaryText={translate('pos.configuration')}
//       leftIcon={<SettingsIcon />}
//       onClick={props.onClick}
//       sidebarIsOpen
//     />
//   );
// });

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>{/* <ConfigurationMenu />*/}</UserMenu>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  return (
    <AppBar
      {...props}
      className={classnames(classes.bar, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
