import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import { sanitizeFieldRestProps } from 'react-admin';
import { getUrlFromFileData } from '../../helpers/upload';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from '../../types/upload';

const FileField = (props: FileFieldProps) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    title,
    src,
    target,
    download,
    ping,
    rel,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);
  const classes = useStyles(props);

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div
        className={classnames(classes.root, className)}
        {...sanitizeFieldRestProps(rest)}
      />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={classnames(classes.root, className)}
        {...sanitizeFieldRestProps(rest)}
      >
        {sourceValue.map((file, index) => {
          const fileTitleValue = (title && get(file, title)) || title;
          const srcValue = getUrlFromFileData(file);

          return (
            <li key={index}>
              <a
                href={srcValue}
                title={fileTitleValue}
                target={target}
                download={download}
                ping={ping}
                rel={rel}
              >
                {fileTitleValue}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = (title && get(record, title)) || title;
  const srcValue = getUrlFromFileData(sourceValue);

  return (
    <div
      className={classnames(classes.root, className)}
      {...sanitizeFieldRestProps(rest)}
    >
      <a
        href={srcValue}
        title={titleValue}
        target={target}
        download={download}
        ping={ping}
        rel={rel}
      >
        {titleValue}
      </a>
    </div>
  );
};

FileField.defaultProps = {
  addLabel: true,
};

const useStyles = makeStyles(
  {
    root: { display: 'inline-block' },
  },
  { name: 'RaFileField' },
);

export interface FileFieldProps extends PublicFieldProps, InjectedFieldProps {
  source: string;
  src?: string;
  title?: string;
  target?: string;
  download?: boolean | string;
  ping?: string;
  rel?: string;
  classes?: Record<string, any>;
}

FileField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ping: PropTypes.string,
  rel: PropTypes.string,
};

export default FileField;
